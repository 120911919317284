import React from 'react';
import './SEOCases.css'; // Reuse the same CSS file for simplicity
import { FaExternalLinkAlt } from 'react-icons/fa';

const seoCases = [
  {
    website: 'primevapes.ie',
    title: 'Prime Vapes Ireland',
    description: 'A successful SEO campaign that increased organic traffic and improved search engine rankings for a leading website.',
  },
  {
    website: 'poshvapes.co.uk',
    title: 'Posh Vapes UK',
    description: 'An extensive digital marketing strategy that boosted online visibility and generated quality leads for our client.',
  },
];

const SEOCases = () => {
  return (
    <div className="cases-section">
      <h2>SEO Cases</h2>
      {seoCases.map((caseData, index) => (
        <div key={index} className="case-card">
          <div className="case-info">
            <div className="case-details">
              <h3>{caseData.title}</h3>
              <p><strong>Website:</strong> 
                <a href={`https://${caseData.website}`} target="_blank" rel="noopener noreferrer">
                  {caseData.website} <FaExternalLinkAlt />
                </a>
              </p>
              <p>{caseData.description}</p>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default SEOCases;
