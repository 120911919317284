import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { FaPlay, FaPause, FaBars, FaTimes } from 'react-icons/fa';
import './Nav.css';
import music from './moonlight.mp3';

const AudioPlayer = () => {
  const [isPlaying, setIsPlaying] = useState(false);

  // Create audio element and handle play/pause
  const [audio] = useState(() => {
    const audio = new Audio(music);
    audio.preload = "none";
    return audio;
  });

  useEffect(() => {
    if (isPlaying) {
      audio.play();
    } else {
      audio.pause();
    }
    return () => {
      audio.pause();
      audio.currentTime = 0;
    };
  }, [isPlaying, audio]);


  const handlePlayPause = () => {
    setIsPlaying(!isPlaying);
  };




  return (
    <div style={{position:'fixed', bottom:'0px', right:'0px', zIndex:'99999'}}>

      {isPlaying && (
        <motion.div
          className="song-info"
          initial={{ opacity: 0, x: 50 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.5 }}
        >
          Moonlight
        </motion.div>
      )}

      <motion.div
        className="play-button"
        onClick={handlePlayPause}
        animate={{ rotate: isPlaying ? 360 : 0 }}
        transition={{ duration: 0.5 }}
      >
        {isPlaying ? <FaPause /> : <FaPlay />}
      </motion.div>



    </div>
  );
};

export default AudioPlayer;
