import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import './GraphicDetails.css';
import SocialMediaPopup from '../components/popup/SocialMediaPopup';
import Modal from 'react-modal';
import { useNavigate } from 'react-router-dom';
import { FaFacebook, FaTwitter, FaChevronLeft } from 'react-icons/fa';

// Import images dynamically based on company name
const importImages = (r) => r.keys().map(r);
const imagesByCompany = {
  'desirehub': importImages(require.context('../assets/desirehub', false, /\.(png|jpe?g|svg)$/)),
  'queenstyping': importImages(require.context('../assets/queenstyping', false, /\.(png|jpe?g|svg)$/)),
  'syedbrothers': importImages(require.context('../assets/syedbrothers', false, /\.(png|jpe?g|svg)$/)),
  'riphah': importImages(require.context('../assets/riphah', false, /\.(png|jpe?g|svg)$/)),
  'standardcollege': importImages(require.context('../assets/standardcollege', false, /\.(png|jpe?g|svg)$/)),
  'primevapes': importImages(require.context('../assets/primevapes', false, /\.(png|jpe?g|svg)$/)),
};

const companyData = {
  'desirehub': {
    title: 'Desire Hub Tourism',
    owner: 'Mr Waseem Khan',
    socialMedia: [
      { icon: FaFacebook, link: 'facebook.com/companya' },
      { icon: FaTwitter, link: 'twitter.com/companya' },
    ],
  },
  'queenstyping': {
    title: 'Queens Typing Services',
    owner: 'Queens',
    socialMedia: [
      { icon: FaFacebook, link: 'facebook.com/companya' },
      { icon: FaTwitter, link: 'twitter.com/companya' },
    ],
  },
  'syedbrothers': {
    title: 'Syed Brothers',
    owner: 'Syed',
    socialMedia: [
      { icon: FaFacebook, link: 'facebook.com/companya' },
      { icon: FaTwitter, link: 'twitter.com/companya' },
    ],
  },
  'riphah': {
    title: 'Riphah International College',
    owner: 'Prof Khalil ur Rehman',
    socialMedia: [
      { icon: FaFacebook, link: 'facebook.com/companya' },
      { icon: FaTwitter, link: 'twitter.com/companya' },
    ],
  },
  'standardcollege': {
    title: 'The Standard Group of Colleges',
    owner: 'Unknown',
    socialMedia: [
      { icon: FaFacebook, link: 'facebook.com/companya' },
      { icon: FaTwitter, link: 'twitter.com/companya' },
    ],
  },
  'primevapes': {
    title: 'Prime Vapes',
    owner: 'Raja Irfan',
    socialMedia: [
      { icon: FaFacebook, link: 'facebook.com/companya' },
      { icon: FaTwitter, link: 'twitter.com/companya' },
    ],
  },
};

const GraphicDetails = () => {
  const navigate = useNavigate();
  const { companyName } = useParams();
  const company = companyData[companyName];
  const images = imagesByCompany[companyName];
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [currentImage, setCurrentImage] = useState('');

  if (!company) {
    return <div>Company not found</div>;
  }

  const openModal = (image) => {
    setCurrentImage(image);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setCurrentImage('');
  };

  const handleBackClick = () => {
    navigate('/graphics');
  };

  return (
    <div className="graphic-details">
      <SocialMediaPopup />
      <div className="back" onClick={handleBackClick}><FaChevronLeft /> &nbsp; Back</div>
      <div className="logo">MW.</div>
      <div style={{ width: '100%', display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
        <h1>{company.title}</h1>
        <h2 style={{ fontSize: '16px', marginTop: '8px', opacity: '80%' }}>{company.owner}</h2>
        <div className="companySocialMedia">
          {company.socialMedia.map((social, index) => {
            const Icon = social.icon;
            return (
              <a style={{ color: 'darkgrey', width: '18px', height: '18px' }} key={index} href={`https://${social.link}`} target="_blank" rel="noopener noreferrer">
                <Icon size={25} />
              </a>
            );
          })}
        </div>
      </div>
      <div className="images">
        {images.map((image, index) => (
          <div key={index} className="image-card" onClick={() => openModal(image)}>
            <img style={{ width: '300px', height: '300px' }} src={image} alt={`${companyName} graphic ${index + 1}`} />
          </div>
        ))}
      </div>
      <Modal isOpen={modalIsOpen} onRequestClose={closeModal} contentLabel="Image Modal">
        <button onClick={closeModal} className="close-button">Close</button>
        <img src={currentImage} alt="Current" className="modal-image" />
      </Modal>
    </div>
  );
};

export default GraphicDetails;
