// ThreeComponent.js
import React, { useRef, useEffect } from 'react';
import * as THREE from 'three';
import { Canvas, useFrame } from '@react-three/fiber';
import { OrbitControls, Stars } from '@react-three/drei';

const AnimatedMesh = () => {
  const meshRef = useRef(null);
  useFrame(() => {
    meshRef.current.rotation.x += 0.01;
    meshRef.current.rotation.y += 0.01;
  });
  return (
    <mesh ref={meshRef} scale={1.5}>
      <icosahedronGeometry args={[1, 0]} />
      <meshStandardMaterial color="#ccc" wireframe />
    </mesh>
  );
};

const Particles = () => {
  const particlesRef = useRef();
  useFrame(() => {
    particlesRef.current.rotation.x += 0.001;
    particlesRef.current.rotation.y += 0.001;
  });
  return (
    <points ref={particlesRef}>
      <sphereGeometry args={[4, 32, 32]} />
      <pointsMaterial color="linear-gradient(90deg, rgba(250,5,5,1) 0%, rgba(18,0,147,1) 100%)" size={0.02} />
    </points>
  );
};

const ThreeComponent = () => {
  return (
    <Canvas style={{ height: '100%', width: '100%' }}>
      <ambientLight intensity={0.5} />
      <pointLight position={[10, 10, 10]} intensity={1} />
      <spotLight position={[-10, -10, -10]} angle={0.15} penumbra={1} />
      <AnimatedMesh />
      <Particles />
      <Stars radius={300} depth={50} count={5000} factor={4} saturation={0} fade speed={1} />
      <OrbitControls />
    </Canvas>
  );
};

export default ThreeComponent;
