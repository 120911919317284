import React from 'react';
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import { FaChevronLeft } from "react-icons/fa";
import './GraphicDesigning.css';
import SocialMediaPopup from '../components/popup/SocialMediaPopup';
import thumb1 from '../assets/thumbnail/1.png';
import thumb2 from '../assets/thumbnail/2.png';
import thumb3 from '../assets/thumbnail/3.png';
import thumb4 from '../assets/thumbnail/4.png';
import thumb5 from '../assets/thumbnail/5.png';
import thumb6 from '../assets/thumbnail/6.png';
const graphicsData = [
  { title: 'Desire Hub Tourism', thumbnail: thumb1,link: 'desirehub' },
  { title: 'Queens Typing Services', thumbnail: thumb2, link: 'queenstyping' },
  { title: 'Syed Brothers', thumbnail: thumb3, link: 'syedbrothers' },
  { title: 'Riphah International College', thumbnail: thumb4, link: 'riphah' },
  { title: 'The Standard Group of College', thumbnail: thumb5,link: 'standardcollege' },
  { title: 'Prime Vapes', thumbnail: thumb6,link:'primevapes' },
//   { title: 'Posh Vapes', thumbnail: 'thumbnailC.jpg' },
//   { title: 'Waqas Mirza Wear', thumbnail: 'thumbnailC.jpg' },
//   { title: 'UI/UX', thumbnail: 'thumbnailC.jpg' },
//   { title: 'Local', thumbnail: 'thumbnailC.jpg' },
];

const GraphicDesigning = () => {
  const navigate = useNavigate();

  const handleCardClick = (title) => {
    navigate(`/graphics/${title}`);
  };
  const handleBackClick = () => {
    navigate('/work');
  };
  return (
    <div className="graphic-designing">
      <SocialMediaPopup />
      <div className="back" onClick={handleBackClick}><FaChevronLeft /> &nbsp; Back</div>
      <div className="logo">MW.</div>

      <motion.div
        className="graphic-cards"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1, ease: "easeInOut" }}
      >
        
        {graphicsData.map((item, index) => (
          <motion.div
            className="graphic-card"
            key={index}
            onClick={() => handleCardClick(item.link)}
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.9 }}
          >
            <img src={item.thumbnail} alt={item.title} />
            <h4>{item.title}</h4>
          </motion.div>
        ))}
      </motion.div>
    </div>
  );
};

export default GraphicDesigning;
