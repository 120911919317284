import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import { FaChevronLeft } from "react-icons/fa";
import './Developer.css';
import devThumb1 from '../assets/thumbnail/1.png';
import devThumb2 from '../assets/thumbnail/2.png';
import devThumb3 from '../assets/thumbnail/3.png';
import devThumb4 from '../assets/thumbnail/4.png';
import devThumb5 from '../assets/thumbnail/5.png';
import devThumb6 from '../assets/thumbnail/6.png';
import SocialMediaPopup from '../components/popup/SocialMediaPopup';

const developmentData = [
  { title: 'Fibernet Jhelum', thumbnail: devThumb1, link: 'https://project-one.com', description: 'Description of Project One', date: '2023-01-01' },
  { title: 'Hafiz Trading', thumbnail: devThumb2, link: 'https://project-two.com', description: 'Description of Project Two', date: '2023-02-01' },
  { title: 'My Portfolio', thumbnail: devThumb3, link: 'https://project-three.com', description: 'Description of Project Three', date: '2023-03-01' },
  { title: 'A Store', thumbnail: devThumb4, link: 'https://project-four.com', description: 'Description of Project Four', date: '2023-04-01' },
];

const Developer = () => {
  const [hoveredProject, setHoveredProject] = useState(null);
  const navigate = useNavigate();

  const handleCardClick = (link) => {
    window.open(link, '_blank');
  };

  const handleBackClick = () => {
    navigate('/home');
  };

  return (
    <div className="developer">
      <SocialMediaPopup />
      <div className="back" onClick={handleBackClick}><FaChevronLeft /> &nbsp; Back</div>
      <div className="logo">MW.</div>

      <motion.div
        className="developer-content"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1, ease: "easeInOut" }}
      >
        <div className="developer-cards">
          {developmentData.map((item, index) => (
            <motion.div
              className="developer-card"
              key={index}
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.9 }}
              onMouseEnter={() => setHoveredProject(item)}
              onMouseLeave={() => setHoveredProject(null)}
            >
              <div className="card-content">
                <a href={item.link} target="_blank" rel="noopener noreferrer"><h4>{item.title}</h4></a>
                <p>{item.description}</p>
                <p className="card-date">{item.date}</p>
              </div>
            </motion.div>
          ))}
        </div>
        <div className="developer-preview">
          {hoveredProject && (
            <motion.img
              src={hoveredProject.thumbnail}
              alt={hoveredProject.title}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.3 }}
            />
          )}
        </div>
      </motion.div>
    </div>
  );
};

export default Developer;
