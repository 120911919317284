
import React from 'react';
import { useNavigate } from 'react-router-dom';
import './ProjectSection.css';
import { FaChevronLeft } from "react-icons/fa";
import ThreeComponent from '../ThreeComponent';

const projects = [
  { title: 'Desire Hub Tourism', date: '2023-01-01', category: 'Graphic Design', link: '/desirehub' },
  { title: 'Queen Hub Typing Services', date: '2023-02-01', category: 'Graphic Design', link: '/queenshub' },
  { title: 'Syed Brothers', date: '2023-02-01', category: 'Graphic Design', link: '/syedbrothers' },
  { title: 'Ikhsan Khan Documents Clearing', date: '2023-02-01', category: 'Graphic Design', link: '/ikhsan' },
  { title: 'Prime Vapes', date: '2023-03-01', category: 'Shopify/Graphic Designing/Seo Manager', link: '/primevapes' },
  { title: 'Riphah International College', date: '2023-04-01', category: 'Graphic Design', link: '/riphah' },
  { title: 'The Standard Group of Colleges', date: '2023-04-01', category: 'Graphic Design', link: '/standard' },
  { title: 'Posh Vapes', date: '2023-04-01', category: 'Shopify/Graphic Designing/Seo Manager', link: '/poshvapes' },
  { title: 'Trend of Styles', date: '2023-04-01', category: 'Shopify/Graphic Designing/Seo Manager', link: '/trendofstyles' },
  { title: 'My Portoflio', date: '2023-04-01', category: 'Web Developer', link: '/myportfolio' },
  { title: 'Hafiz Diapers Trading', date: '2023-04-01', category: 'Software Development', link: '/hafizdiapers' },
  { title: 'Fiber Net Jhelum', date: '2023-04-01', category: 'Software Development', link: '/fibernetjhelum' },
  { title: 'Students Project', date: '2023-04-01', category: 'Development', link: '/studentsproject' },
];

const ProjectsSection = () => {
  const navigate = useNavigate();

  const handleBackClick = () => {
    navigate('/home');
  };
  return (
    <div className="projects-section">
        <div className="back" onClick={handleBackClick}><FaChevronLeft /> &nbsp; Back</div>
      <div className="three-container">
        <ThreeComponent />
      </div>
      <div className="projects-list">
        <h2 className="projects-title">PROJECTS</h2>
        {projects.map((project, index) => (
          <div key={index} className="project-card" onClick={() => navigate(project.link)}>
            <div className="project-info">
              <span>{project.category}</span>
              <h4>{project.title}</h4>
              <p>{project.date}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ProjectsSection;
