import React, { useEffect, useState } from 'react';
import './CircleFollower.css';

const CircleFollower = () => {
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [scale, setScale] = useState(1);
  const [hovered, setHovered] = useState(false);

  const handleMouseMove = (e) => {
    setPosition({ x: e.clientX, y: e.clientY });
  };

  const handleMouseDown = () => {
    setScale(2);
    document.querySelector('.circle').classList.add('scaled');
  };

  const handleMouseUp = () => {
    setScale(1);
    document.querySelector('.circle').classList.remove('scaled');
  };

  const handleMouseEnter = () => {
    setHovered(true);
  };

  const handleMouseLeave = () => {
    setHovered(false);
  };

  useEffect(() => {
    window.addEventListener('mousemove', handleMouseMove);
    window.addEventListener('mousedown', handleMouseDown);
    window.addEventListener('mouseup', handleMouseUp);

    // Add event listeners to all elements for hover detection
    const hoverElements = document.querySelectorAll('*');
    hoverElements.forEach((element) => {
      element.addEventListener('mouseenter', handleMouseEnter);
      element.addEventListener('mouseleave', handleMouseLeave);
    });

    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('mousedown', handleMouseDown);
      window.removeEventListener('mouseup', handleMouseUp);

      hoverElements.forEach((element) => {
        element.removeEventListener('mouseenter', handleMouseEnter);
        element.removeEventListener('mouseleave', handleMouseLeave);
      });
    };
  }, []);

  return (
    <div
      className={`circle ${hovered ? 'hovered' : ''}`}
      style={{
        transform: `translate(${position.x - 12}px, ${position.y - 12}px) scale(${scale})`,
      }}
    >
      <div className="dot"></div>
    </div>
  );
};

export default CircleFollower;
