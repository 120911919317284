
import React, { useState, useEffect } from 'react';
import { GoChevronRight } from "react-icons/go";
import { motion } from 'framer-motion';

import { useNavigate } from 'react-router-dom';
import './Home.css';
import SocialMediaPopup from '../components/popup/SocialMediaPopup';
import Feedback from '../components/feedbacks/Feedback';
import Nav from '../components/nav/Nav';
import SkillsSection from '../components/skillsection/SkillsSection';
import ParticleBackground from '../components/particles/ParticleBackground';

const Home = () => {

  const navigate = useNavigate(); 

  const handleScroll = (e) => {
    const content = document.getElementById('content');
    if (content) {
      content.style.opacity = 1 - window.scrollY / 400; // Adjust opacity based on scroll position
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);


  return (
    <div className="home" >
      <div className='bg'></div>
      <SocialMediaPopup/>
      <Nav/>

      <motion.div
        className="content"
        id="content"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1, ease: "easeInOut" }}
      >
        <motion.div className="line"
        id="line"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1, ease: "easeInOut" }}>
          &rarr;
          <a 
          className="projectButton" 
          style={{fontSize:'12px', 
                  textAlign:'center',
                  color:'white', 
                  textDecoration:'none'}} onClick={() => navigate('/work')}>PROJECTS</a>
        </motion.div>
        <motion.h1
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1, ease: "easeInOut" }}
        >
          <span style={{ fontSize: '24px', color:'darkgrey' }}>Hello,</span><br />
          I'm Mirza Waleed, <br></br>A Professional <span className='highlighted'>Developer</span> and <span className='highlighted'>Designer</span>.
        </motion.h1>
      </motion.div>
    </div>
  );
};

export default Home;
