// src/Components/SocialMediaPopup.js
import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { FaFacebookF, FaBehance, FaInstagram, FaPlay } from 'react-icons/fa';
import './SocialMediaPopup.css';

const SocialMediaPopup = () => {
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className={`social-media-popup ${isScrolled ? 'scrolled' : ''}`}>
      <motion.div
        className="popup-content"
        initial={{ opacity: 0, scale: 0.8 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 0.3 }}
      >
        <motion.a
          href="https://facebook.com/fbwaleedmirza"
          target="_blank"
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.9 }}
        >
          <FaFacebookF />
        </motion.a>
        <motion.a
          href="https://behance.net/waleedmirza0"
          target="_blank"
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.9 }}
        >
          <FaBehance />
        </motion.a>
        <motion.a
          href="https://instagram.com/waleedmirza0"
          target="_blank"
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.9 }}
        >
          <FaInstagram />
        </motion.a>
        <motion.a 
          href="/auto"
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.9 }}>
        <FaPlay/>
        </motion.a>
      </motion.div>
    </div>
  );
};

export default SocialMediaPopup;
