import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { GoArrowRight } from "react-icons/go";
import './AutoScreen.css';

const importImages = (r) => r.keys().map(r);
const imagesByCompany = {
  'desirehub': importImages(require.context('../assets/desirehub', false, /\.(png|jpe?g|svg)$/)),
  'queenstyping': importImages(require.context('../assets/queenstyping', false, /\.(png|jpe?g|svg)$/)),
  'syedbrothers': importImages(require.context('../assets/syedbrothers', false, /\.(png|jpe?g|svg)$/)),
  'riphah': importImages(require.context('../assets/riphah', false, /\.(png|jpe?g|svg)$/)),
  'standardcollege': importImages(require.context('../assets/standardcollege', false, /\.(png|jpe?g|svg)$/)),
  'primevapes': importImages(require.context('../assets/primevapes', false, /\.(png|jpe?g|svg)$/)),
};

const companyData = {
  'desirehub': {
    title: 'Desire Hub Tourism',
    owner: 'Mr Waseem Khan',
  },
  'queenstyping': {
    title: 'Queens Typing Services',
    owner: 'Queens',
  },
  'syedbrothers': {
    title: 'Syed Brothers',
    owner: 'Syed',
  },
  'riphah': {
    title: 'Riphah International College',
    owner: 'Prof Khalil ur Rehman',
  },
  'standardcollege': {
    title: 'The Standard Group of Colleges',
    owner: 'Unknown',
  },
  'primevapes': {
    title: 'Prime Vapes',
    owner: 'Raja Irfan',
  },
};

const AutoScreen = () => {
  const introTexts = ["Hi", "I am Waleed Mirza", "A Professional", "Graphic Designer", "Developer."];
  const [currentStep, setCurrentStep] = useState(0);
  const [currentProject, setCurrentProject] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      if (currentStep < introTexts.length) {
        setCurrentStep((prevStep) => prevStep + 1);
      }
    }, 2000);

    return () => clearInterval(interval);
  }, [currentStep]);

  const handleNextProject = () => {
    setCurrentProject((prevProject) => (prevProject + 1) % Object.keys(companyData).length);
  };

  const currentCompanyKey = Object.keys(companyData)[currentProject];
  const company = companyData[currentCompanyKey];
  const images = imagesByCompany[currentCompanyKey];

  return (
    <div className="auto-screen">
      <AnimatePresence>
        {currentStep < introTexts.length && (
          <motion.div
            key={introTexts[currentStep]}
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -50 }}
            transition={{ duration: 1, ease: "easeInOut" }}
            className="intro-text"
          >
            {introTexts[currentStep]}
          </motion.div>
        )}

        {currentStep >= introTexts.length && (
          <motion.div
            key={currentCompanyKey}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 1 }}
            className="work-case-container"
          >
           <div className='header-container'>
            <div className="company-details">
              <div className="company-name">{company.title}</div>
              <div className="work-name">{company.owner}</div>
            </div>
            
           <button onClick={handleNextProject} className="next-button"><GoArrowRight /></button>
           </div>
            <div className="grid-container">
              {images.map((image, index) => (
                <motion.div
                  key={index}
                  className="grid-item"
                  drag="x"
                  dragConstraints={{ left: -100, right: 100 }}
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ delay: index * 0.2, duration: 0.5 }}
                >
                  <img src={image} alt={`${company.title} graphic ${index + 1}`} />
                </motion.div>
              ))}
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default AutoScreen;
