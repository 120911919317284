import React from 'react';
import './ECommerceCases.css';
import { FaExternalLinkAlt } from 'react-icons/fa';

const ecommerceCases = [
  {
    website: 'primevapes.ie',
    title: 'Prime Vapes',
    description: 'A modern and user-friendly online shop with a wide range of products, seamless navigation, and secure payment options.',
  },
  {
    website: 'poshvapes.co.uk',
    title: 'Posh Vapes Uk',
    description: 'An online store offering a great selection of items, intuitive browsing experience, and fast shipping services.',
  },
];

const ECommerceCases = () => {
  return (
    <div className="cases-section">
      <h2>E-Commerce Cases</h2>
      {ecommerceCases.map((caseData, index) => (
        <div key={index} className="case-card">
          <div className="case-info">
            <div className="case-details">
              <h3>{caseData.title}</h3>
              <p><strong>Website:</strong> 
                <a href={`https://${caseData.website}`} target="_blank" rel="noopener noreferrer">
                  {caseData.website} <FaExternalLinkAlt />
                </a>
              </p>
              <p>{caseData.description}</p>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default ECommerceCases;
