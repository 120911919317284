import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';
import SplashScreen from './splash/SplashScreen';
import Home from './main/Home';
import './App.css';
import GraphicDesigning from './GraphicDesigning/GraphicDesigning';
import GraphicDetails from './GraphicDetails/GraphicDetails';
import AutoScreen from './auto/AutoScreen';
import Developer from './developer/Developer';
import CircleFollower from './components/circle/CircleFollower';
import ECommerceCases from './Com/ECommerceCases';
import SEOCases from './seo/SEOCases';
import ProjectsSection from './components/ProjectSection/ProjectSection';
import DesireHub from './Projects/DesireHub/DesireHub';
import QueenHub from './Projects/QueenHub/QueenHub';
import SyedBrothers from './Projects/SyedBrothers/SyedBrothers';
import Standard from './Projects/Standard/Standard';
import Riphah from './Projects/Riphah/Riphah';
import PrimeVapes from './Projects/PrimeVapes/PrimeVapes';
import PoshVapes from './Projects/PoshVapes/PoshVapes';
import Trendofstyles from './Projects/TrendofStyles/Trendofstyles';
import DefaultProject from './Projects/DefaultProject';
import AudioPlayer from './components/nav/AudioContext';

function App() {
  return (

    <Router>
      <AudioPlayer/>
      <AnimatePresence>
        <CircleFollower/>
        <Routes>
          <Route exact path="/" element={<SplashScreen />} />
          <Route path="/home" element={<Home />} />
          <Route path="/auto" element={<AutoScreen />} />
          <Route path="/work" element={<ProjectsSection/>} />
          <Route path="/desirehub" element={<DesireHub />} />
          <Route path="/queenshub" element={<QueenHub />} />
          <Route path="/syedbrothers" element={<SyedBrothers />} />
          <Route path="/riphah" element={<Riphah />} />
          <Route path="/standard" element={<Standard />} />

          
          <Route path="/primevapes" element={<PrimeVapes />} />
          <Route path="/poshvapes" element={<PoshVapes />} />
          <Route path="/trendofstyles" element={<Trendofstyles />} />

          <Route path="/ikhsan" element={<DefaultProject />} />
          <Route path="/hafizdiapers" element={<DefaultProject />} />
          <Route path="/myportfolio" element={<DefaultProject />} />
          <Route path="/studentsproject" element={<DefaultProject />} />
          <Route path="/fibernetjhelum" element={<DefaultProject />} />
          
          <Route path="/dev" element={<Developer/>} />
          <Route path="/ecom" element={<ECommerceCases />} />
          <Route path="/seo" element={<SEOCases />} />
          <Route path="/graphics" element={<GraphicDesigning />} />
          <Route path="/graphics/:companyName" element={<GraphicDetails />} />
        </Routes>
      </AnimatePresence>
    </Router>
  
  );
}

export default App;
