
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Modal from 'react-modal';
import { FaChevronLeft } from 'react-icons/fa';
import './DesireHub.css';

const usePreloadImages = (imagePaths) => {
  const [loadedImages, setLoadedImages] = useState([]);

  useEffect(() => {
    const images = [];
    imagePaths.forEach((path) => {
      const img = new Image();
      img.src = path;
      img.onload = () => {
        images.push(path);
        if (images.length === imagePaths.length) {
          setLoadedImages(images);
        }
      };
    });
  }, [imagePaths]);

  return loadedImages;
};

const importImages = (r) => r.keys().map(r);

const DesireHub = () => {
  const navigate = useNavigate();

  const handleBackClick = () => {
    navigate('/work');
  };
  
  const company = "desirehub";
  const images = importImages(require.context('../../assets/desirehub', false, /\.(png|jpe?g|svg)$/));
  const preloadedImages = usePreloadImages(images);

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [currentImage, setCurrentImage] = useState('');

  const openModal = (image) => {
    setCurrentImage(image);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setCurrentImage('');
  };


  return (
    <div className="graphic-details">
      <div className="back" onClick={handleBackClick}><FaChevronLeft /> &nbsp; Back</div>
      <div className="logo">MW.</div>
      <div style={{ width: '100%', display: 'flex', justifyContent: 'center', flexDirection: 'column', marginTop:'40px'}}>
        <h1>Desire Hub Tourism</h1>
        <h2 style={{ fontSize: '16px', marginTop: '8px', opacity: '80%' }}>Mr Waseem Khan</h2>

      </div>
      <div className="images">
        {preloadedImages.length === 0 ? (
          <p>Loading images...</p>
        ) : (
          <>
            <div className="image-card large" onClick={() => openModal(preloadedImages[0])}>
              <img src={preloadedImages[0]} alt={`Desire Hub Graphic 1`} />
            </div>
            {preloadedImages.slice(1).map((image, index) => (
              <div key={index + 1} className="image-card small" onClick={() => openModal(image)}>
                <img src={image} alt={`Desire Hub Graphic ${index + 2}`} />
              </div>
            ))}
          </>
        )}
      </div>
      <Modal isOpen={modalIsOpen} onRequestClose={closeModal} contentLabel="Image Modal" className="custom-modal">
        <button onClick={closeModal} className="close-button">Close</button>
        <img src={currentImage} alt="Current" className="modal-image" />
      </Modal>
    </div>
  );
};

export default DesireHub;
