import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Modal from 'react-modal';
import { FaChevronLeft } from 'react-icons/fa';
import './PoshVapes.css';

const importImages = (r) => r.keys().map(r);

const PoshVapes = () => {
  const navigate = useNavigate();
  const [images, setImages] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [currentImage, setCurrentImage] = useState('');

  useEffect(() => {
    const graphicImages = importImages(require.context('../../assets/poshvapes', false, /\.(png|jpe?g|svg)$/));
    
    setImages({ graphics: graphicImages });
  }, []);

  const openModal = (image) => {
    setCurrentImage(image);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setCurrentImage('');
  };

  const handleBackClick = () => {
    navigate('/work');
  };

  return (
    <div className="work-case-page">
      <div className="back" onClick={handleBackClick}><FaChevronLeft /> &nbsp; Back</div>
      <div className="header">
        <h1>Posh Vapes</h1>
        <h3>E-Commerce / Shopify Project</h3>
        <p>Project Overview and Details</p>
      </div>
      <section className="project-details">
        <div className="section">
        <p>
        I started working on <a href='https://poshvapes.co.uk' target='_blank' rel='noopener noreferrer'>PoshVapes.co.uk</a> in 2023 to build a Shopify store and manage its sales. 
         Despite the intense competition in the UK, I secured the first order within a month after implementing SEO. The role involves a significant amount of graphic work, daily updates on the site, and duties as a Virtual Assistant.
      </p>

      <h2>Website Screenshots & Graphic Designing</h2>
          <div className="images-grid">
            {images.graphics && images.graphics.length > 0 && (
              <>
                <div className="image-card large" onClick={() => openModal(images.graphics[0])}>
                  <img src={images.graphics[0]} alt={images.graphics[0].split('/').pop()} />
                </div>
                {images.graphics.slice(1).map((image, index) => (
                  <div key={index} className="image-card small" onClick={() => openModal(image)}>
                    <img src={image} alt={image.split('/').pop()} />
                  </div>
                ))}
              </>
            )}
          </div>
        </div>
      </section>
      <Modal isOpen={modalIsOpen} onRequestClose={closeModal} contentLabel="Image Modal" className="custom-modal">
        <button onClick={closeModal} className="close-button">Close</button>
        <img src={currentImage} alt="Current" className="modal-image" />
      </Modal>
    </div>
  );
};

export default PoshVapes;
