import React from 'react';
import { useNavigate } from 'react-router-dom';
import { FaChevronLeft } from 'react-icons/fa';
import './DefaultProject.css';

const DefaultProject = () => {
  const navigate = useNavigate();

  const handleBackClick = () => {
    navigate('/work'); 
  };

  return (
    <div className="project-default-page">
      <div className="back" onClick={handleBackClick}><FaChevronLeft /> &nbsp; Back</div>
      <div className="content">
        <h1>Project Details Coming Soon</h1>
        <p>We are currently working on the details for this project. Please check back later for more information.</p>
      </div>
    </div>
  );
};

export default DefaultProject;
