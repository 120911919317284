import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Modal from 'react-modal';
import { FaChevronLeft } from 'react-icons/fa';
import './PrimeVapes.css';

const importImages = (r) => r.keys().map(r);

const PrimeVapes = () => {
  const navigate = useNavigate();
  const [images, setImages] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [currentImage, setCurrentImage] = useState('');

  useEffect(() => {
    const screenshotImages = importImages(require.context('../../assets/screenshotprimevapes', false, /\.(png|jpe?g|svg)$/));
    const graphicImages = importImages(require.context('../../assets/primevapes', false, /\.(png|jpe?g|svg)$/));
    
    setImages({ screenshots: screenshotImages, graphics: graphicImages });
  }, []);

  const openModal = (image) => {
    setCurrentImage(image);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setCurrentImage('');
  };

  const handleBackClick = () => {
    navigate('/work');
  };

  return (
    <div className="work-case-page">
      <div className="back" onClick={handleBackClick}><FaChevronLeft /> &nbsp; Back</div>
      <div className="header">
        <h1>E-Commerce / Shopify Project</h1>
        <p>Project Overview and Details</p>
      </div>
      <section className="project-details">
        <div className="section">
        <p>
  I started working on <a href='https://primevapes.ie' target='_blank' rel='noopener noreferrer'>PrimeVapes.ie</a> in 2022 to build a Shopify store and manage its sales. Initially, I faced challenges with the payment gateway due to the 18+ nature of the products, as many payment gateway providers rejected our proposal. We used PayPal, but it only offered payment through PayPal accounts. Since we needed card payments, I developed custom scripts to facilitate PayPal card payments indirectly via the API. After a few months, we switched to a different payment gateway.

  Later, I encountered issues with sales, as advertising on Google or Facebook was not an option due to the nature of the products. Consequently, I focused on SEO, performing two months of on-page SEO work. As a result, we received our first order, and the weekly sales now amount to €4000. I continue to work on it as a Virtual Assistant, SEO Manager, and Graphic Designer.
</p>

<h2>Website Screenshots</h2>

          <div className="images-grid">
            {images.screenshots && images.screenshots.length > 0 && (
              <>
                <div className="image-card large" onClick={() => openModal(images.screenshots[0])}>
                  <img src={images.screenshots[0]} alt={images.screenshots[0].split('/').pop()} />
                </div>
                {images.screenshots.slice(1).map((image, index) => (
                  <div key={index} className="image-card small" onClick={() => openModal(image)}>
                    <img src={image} alt={image.split('/').pop()} />
                  </div>
                ))}
              </>
            )}
          </div>
        </div>
        <div className="section">
          <h2>Graphic Design</h2>
          <div className="images-grid">
            {images.graphics && images.graphics.length > 0 && (
              <>
                <div className="image-card large" onClick={() => openModal(images.graphics[0])}>
                  <img src={images.graphics[0]} alt={images.graphics[0].split('/').pop()} />
                </div>
                {images.graphics.slice(1).map((image, index) => (
                  <div key={index} className="image-card small" onClick={() => openModal(image)}>
                    <img src={image} alt={image.split('/').pop()} />
                  </div>
                ))}
              </>
            )}
          </div>
        </div>
        <div className="section">
          <h2>SEO Work Case</h2>
          <p>
            Detailed description of the SEO strategies implemented:
            <ul>
              <li>Keyword research and optimization</li>
              <li>On-page SEO improvements</li>
              <li>Link building</li>
              <li>Analytics and reporting</li>
            </ul>
          </p>
        </div>
      </section>
      <Modal isOpen={modalIsOpen} onRequestClose={closeModal} contentLabel="Image Modal" className="custom-modal">
        <button onClick={closeModal} className="close-button">Close</button>
        <img src={currentImage} alt="Current" className="modal-image" />
      </Modal>
    </div>
  );
};

export default PrimeVapes;
