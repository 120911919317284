import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { FaBars, FaTimes } from 'react-icons/fa';
import './Nav.css';
import { useNavigate } from 'react-router-dom';

const Nav = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);



  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const navigate = useNavigate();

  return (
    <div className="nav-container">
      <motion.div
        className="hamburger-menu"
        onClick={toggleMenu}
        animate={{ opacity: isMenuOpen ? 0 : 1 }}
        initial={{ opacity: 1 }}
        transition={{ duration: 0.3 }}
      >
        <FaBars />
      </motion.div>

      <motion.div
        className="close-menu"
        onClick={toggleMenu}
        style={{ display: isMenuOpen ? "block" : "none" }}
        animate={{ opacity: isMenuOpen ? 1 : 0 }}
        initial={{ opacity: 0 }}
        transition={{ duration: 0.3 }}
      >
        <FaTimes />
      </motion.div>




      <div className="logo">MW.</div>

      <div className={`full-screen-menu ${isMenuOpen ? 'show' : ''}`}>
        <motion.div className="menu-item"
          whileHover={{ scaleX: 1.2 }}
          initial={{ scaleX: 0 }}
          animate={{ scaleX: 1 }}
          onClick={() => navigate('/about')}
        >
          About
        </motion.div>
        <motion.div className="menu-item"
          whileHover={{ scaleX: 1.2 }}
          initial={{ scaleX: 0 }}
          animate={{ scaleX: 1 }}
          onClick={() => navigate('/work')}
        >
          Projects
        </motion.div>
        <motion.div className="menu-item"
          whileHover={{ scaleX: 1.2 }}
          initial={{ scaleX: 0 }}
          animate={{ scaleX: 1 }}
          onClick={() => navigate('/feedbacks')}
        >
          Feedbacks
        </motion.div>
      </div>
    </div>
  );
};

export default Nav;
